// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //本番：
  firebase: {
    vapidKey:
      'BO96TOxF6FgZQdc4NQs35IbO4CQCHspDWjYhun8KkpsJ2360Ar_h75JwYZqjBmHWgOzQEO_fAy3LXRpjE3NgZ1w',
    apiKey: 'AIzaSyC4evi9Q5iGq40k-6XuDm_y0LQIc24ftrE',
    authDomain: 'meta-live-test.firebaseapp.com',
    databaseURL:
      'https://meta-live-test-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'meta-live-test',
    storageBucket: 'meta-live-test.appspot.com',
    messagingSenderId: '1042502003654',
    appId: '1:1042502003654:web:54b75bdbd30a6831d020c7',
    measurementId: 'G-0BKW7H6FJC'
  },
  //ステージング：
  // firebase; {
  //   apiKey: "AIzaSyANi2_D2IgPV7Ug9UfOx21hXaEOYDmj6nU",
  //   authDomain: "met-live-test.firebaseapp.com",
  //   databaseURL: "https://met-live-test-default-rtdb.firebaseio.com",
  //   projectId: "met-live-test",
  //   storageBucket: "met-live-test.appspot.com",
  //   messagingSenderId: "710891298073",
  //   appId: "1:710891298073:web:ccea15af87cc61bac87988",
  //   measurementId: "G-WGRL40VL61",
  //   vapidKey: "BKQUOKXpVdvNn9aUKVj447P6BQSPAyKlaYXJKS_x_u1718MnobiFSNo5XtQBUhVqkt6Mk00CFr6jRt7ShywIS8M"
  // },

  //api_url: 'http://127.0.0.1:8000/api',
  //api_url: 'https://cyan-paws-arrive-133-175-237-241.loca.lt/api',
  //api_url: 'https://develop.d-kaigi.com/api',
  api_url: 'https://app.j-metalive.com/api',
  //api_url: 'https://39cb-240f-97-8289-1-9094-f022-d773-6312.ngrok.io/api',
  environment: 'local'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
