import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { Estimate } from '../models/estimate';
import { Kbn } from '../models/kbn';
import { Group } from '../models/group';
import { environment } from '../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { Market, MarketListResponse } from '../models/market';
import { Question, QuestionListResponse } from '../models/question';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  currentToken: string;
  public user: Observable<any>;
  AUTH_SERVER: string = environment.api_url;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer '
    })
  };
  httpOptions3: {} = undefined;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private loadingService: LoadingService
  ) {}

  async setHeader() {
    await this.storageService.get('accessToken').then((val) => {
      this.currentToken = val;
      return (this.httpOptions3 = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.currentToken
        })
      });
    });
  }

  updateUser(token, userInfo: User): Observable<User> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post<User>(
      `${this.AUTH_SERVER}/auth/update_user`,
      userInfo,
      this.httpOptions
    );
  }

  getUserProfile(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .get(`${this.AUTH_SERVER}/auth/user_profile`, this.httpOptions)
      .pipe(
        map((res: any) => {
          this.storageService.set(
            'currentUser',
            JSON.stringify(res.result.result)
          );
          return res.result;
        })
      );
  }

  savePushToken(token, pushToken, status) {
    let push = {
      push_message_id: pushToken,
      push_message_status: status //0 is false -> no push message string, 1 is true -> send token
    };
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post<any>(
      `${this.AUTH_SERVER}/auth/update_push_id`,
      push,
      this.httpOptions
    );
  }

  updateFirebaseId(token, userInfo: User): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post<any>(
      `${this.AUTH_SERVER}/auth/update_firebase_id`,
      userInfo,
      this.httpOptions
    );
  }

  updateCounters(
    token,
    userInfo: any,
    changeAllCounter: boolean
  ): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    let dataToSend = userInfo;
    dataToSend.change_all_counter = changeAllCounter;
    return this.httpClient.post<any>(
      `${this.AUTH_SERVER}/auth/update_counters`,
      dataToSend,
      this.httpOptions
    );
  }

  updateCounterSettings(token, settings): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post<any>(
      `${this.AUTH_SERVER}/auth/update_counter_settings`,
      settings,
      this.httpOptions
    );
  }

  updateMarketCounter(token, userInfo: User): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post<any>(
      `${this.AUTH_SERVER}/auth/update_market_counter`,
      userInfo,
      this.httpOptions
    );
  }

  updateSystemMessagesCounter(token, userInfo: User): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post<any>(
      `${this.AUTH_SERVER}/auth/update_system_messages_counter`,
      userInfo,
      this.httpOptions
    );
  }

  updateConversation(token, conversationInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    /**
     * target_user_id
     * conversation_id
     */
    return this.httpClient.post<any>(
      `${this.AUTH_SERVER}/member/update_conversation`,
      conversationInfo,
      this.httpOptions
    );
  }

  sendPushNotification(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get(
      `${this.AUTH_SERVER}/push_notification/send`,
      this.httpOptions
    );
  }

  getMarketsIndex(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get(
      `${this.AUTH_SERVER}/market/get_markets`,
      this.httpOptions
    );
  }

  getMarketsIndexV2(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get(
      `${this.AUTH_SERVER}/market/get_markets_v2`,
      this.httpOptions
    );
  }

  showMarket(token, date): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/market/get_market_detail`,
      date,
      this.httpOptions
    );
  }

  showSpecificMarket(token, marketInformation): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/market/get_market_list`,
      marketInformation,
      this.httpOptions
    );
  }

  getPaymentPlans(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get(
      `${this.AUTH_SERVER}/plan/get_plan`,
      this.httpOptions
    );
  }

  getPaymentPlansWithoutJWT(): Observable<any> {
    return this.httpClient.get(`${this.AUTH_SERVER}/plan/get_plan`);
  }

  sendPurchaseInformation(token, marketInformation): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/payment_check/create_claim_infomation`,
      marketInformation,
      this.httpOptions
    );
  }

  getPlanDetails(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get(
      `${this.AUTH_SERVER}/join_plan/get_paid_plan`,
      this.httpOptions
    );
  }

  getOwnPlans(token, user): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/join_plan/get_join_plan`,
      user,
      this.httpOptions
    );
  }

  changePlan(token, plan): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/join_plan/change_join_plan`,
      plan,
      this.httpOptions
    );
  }

  removePlan(token, plan): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/join_plan/delete_plan`,
      plan,
      this.httpOptions
    );
  }

  profileUser(token): Observable<any> {
    let user = this.authService.getCurrentUser();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/auth/user_profile`,
      user,
      this.httpOptions
    );
  }

  updatePassword(token, passwordInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/auth/password_reset`,
        passwordInfo,
        this.httpOptions
      )
      .pipe(catchError(this.handleError<any>('Password change error')));
  }

  UpdateEstimateCounter(token, target_user_id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/member/update_estimate_counter`,
        target_user_id,
        this.httpOptions
      )
      .pipe(catchError(this.handleError<any>('Update Estimate counter')));
  }

  addContact(token, id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(`${this.AUTH_SERVER}/member/store`, id, this.httpOptions)
      .pipe(catchError(this.handleError<any>('Add Contact')));
  }

  addContactViaCompany(token, id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/company/add_all_company_member`,
        id,
        this.httpOptions
      )
      .pipe(catchError(this.handleError<any>('Add Contact')));
  }

  addContactToCompany(token, id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/member/store_company`,
        id,
        this.httpOptions
      )
      .pipe(catchError(this.handleError<any>('Add Contact')));
  }

  addGroup(token, group: Group): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(`${this.AUTH_SERVER}/group/store`, group, this.httpOptions)
      .pipe(catchError(this.handleError<any>('Add Group')));
  }

  getGroupsList(token, id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .get<Group[]>(
        `${this.AUTH_SERVER}/group/get_group_list`,
        this.httpOptions
      )
      .pipe(
        tap((Groups) => console.log('Groups fetched!')),
        catchError(this.handleError<Group[]>('Get Groups', []))
      );
  }

  getGroupDetails(token, id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<Group[]>(
        `${this.AUTH_SERVER}/group/get_group`,
        id,
        this.httpOptions
      )
      .pipe(
        tap((Groups) => console.log('Group details fetched!')),
        catchError(this.handleError<Group[]>('Group detail error', []))
      );
  }

  deleteGroup(token, groupId): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<Group[]>(
        `${this.AUTH_SERVER}/group/delete_group`,
        groupId,
        this.httpOptions
      )
      .pipe(
        tap((Groups) => console.log('Group details fetched!')),
        catchError(this.handleError<Group[]>('Group detail error', []))
      );
  }

  saveGroup(token, group, clientList): Observable<any> {
    const param = {
      group_id: group.group_id,
      group_name: group.group_name,
      group_list: clientList
    };

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<Group[]>(
        `${this.AUTH_SERVER}/group/change_group`,
        param,
        this.httpOptions
      )
      .pipe(
        tap((Groups) => console.log('Group details fetched!')),
        catchError(this.handleError<Group[]>('Group detail error', []))
      );
  }

  getRanksList(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .get<any>(`${this.AUTH_SERVER}/rank/getList/`, this.httpOptions)
      .pipe(
        tap((Groups) => console.log('Ranks fetched!')),
        catchError(this.handleError<any>('Get Ranks', []))
      );
  }

  getAllKbnOLD(token) {
    return this.httpClient.get<Kbn[]>(`${this.AUTH_SERVER}/common/get_kbn`);
  }

  getAllKbn(token) {
    return this.httpClient.get<any>(
      `${this.AUTH_SERVER}/static/get_all_categories`
    );
  }

  addMail(mail: any, kbn): Observable<any> {
    mail.kbn = kbn;
    return this.httpClient
      .post<any>(`${this.AUTH_SERVER}/auth/reset_mail`, mail)
      .pipe(catchError(this.handleError<any>('Add mail')));
  }

  cannotLogin(mail: any, kbn): Observable<any> {
    mail.kbn = kbn;
    return this.httpClient
      .post<any>(`${this.AUTH_SERVER}/auth/cannot_login`, mail)
      .pipe(catchError(this.handleError<any>('Cannot Login')));
  }

  sendUserNotification(token, notification): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post<any>(
      `${this.AUTH_SERVER}/user_notice/store`,
      notification,
      this.httpOptions
    );
  }

  addOrUpdateEstimate(token, estimateInfo: Estimate): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post<Estimate>(
      `${this.AUTH_SERVER}/estimate/store`,
      estimateInfo,
      this.httpOptions
    );
  }

  addOrUpdateEstimateFromShare(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post<any>(
      `${this.AUTH_SERVER}/estimate/update_share_type`,
      estimateInfo,
      this.httpOptions
    );
  }

  addEstimate(estimate: Estimate): Observable<any> {
    return this.httpClient
      .post<Estimate>(
        `${this.AUTH_SERVER}/estimate/store`,
        estimate,
        this.httpOptions
      )
      .pipe(catchError(this.handleError<Estimate>('Add Estimate')));
  }

  getEstimate(token, estimate_id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/estimate/get_estimate`,
        estimate_id,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate fetched: ${estimate_id}`)),
        catchError(this.handleError<any>(`Get Estimate id=${estimate_id}`))
      );
  }

  getEstimatesList(token, user): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/estimate/get_estimate_list`,
        user,
        this.httpOptions
      )
      .pipe(
        tap((Estimates) => console.log('Estimates fetched!')),
        catchError(this.handleError<Estimate[]>('Get Estimates', []))
      );
  }

  getEstimatesListAfterUpdate(token, user): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/estimate/get_estimate_list`,
        user,
        this.httpOptions
      )
      .pipe(
        tap((Estimates) => console.log('Estimates fetched!')),
        catchError(this.handleError<Estimate[]>('Get Estimates', []))
      );
  }

  getMessageList(token, user): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/user_notice/get_message_list`,
        user,
        this.httpOptions
      )
      .pipe(
        tap((Estimates) => console.log('Messages fetched!')),
        catchError(this.handleError<any[]>('Get Estimates', []))
      );
  }

  getAllEstimatesList(token, type): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/estimate/get_estimate_list`,
        type,
        this.httpOptions
      )
      .pipe(
        tap((Estimates) => console.log('Estimates fetched!')),
        catchError(this.handleError<any>('Get Estimates', []))
      );
  }

  updateEstimateContent(token, estimate: Estimate): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/estimate/update_biko2_status`, //update_content
        estimate,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<Estimate[]>('Update Estimate Content'))
      );
  }

  updateEstimateStatus(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    let url;
    url = `${this.AUTH_SERVER}/estimate/update_status`;
    return this.httpClient.post(url, estimateInfo, this.httpOptions).pipe(
      tap((_) => console.log(`Estimate updated`)),
      catchError(this.handleError<any>('Update Estimate Status'))
    );
  }

  updateOwnEstimateStatus(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    let url;
    url = `${this.AUTH_SERVER}/estimate/update_other_user_status`;
    return this.httpClient.post(url, estimateInfo, this.httpOptions).pipe(
      tap((_) => console.log(`Estimate updated`)),
      catchError(this.handleError<any>('Update Estimate Status'))
    );
  }

  //User status:

  updateEstimateToUserStatus(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/estimate/update_to_user_status`,
        estimateInfo,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<any>('Update Estimate Status'))
      );
  }

  updateEstimateFromUserStatus(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/estimate/update_from_user_status`,
        estimateInfo,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<any>('Update Estimate Status'))
      );
  }

  updateEstimateToUserReadStatus(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/estimate/update_to_user_read_status`,
        estimateInfo,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<any>('Update Estimate Status'))
      );
  }

  updateEstimateToUserCompanyFlag(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/estimate/update_to_company_user_flag`,
        estimateInfo,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<any>('Update Estimate Status'))
      );
  }

  updateUserMessagesToUserReadStatus(token, noticeInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/user_notice/update_to_user_read_status`,
        noticeInfo,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<any>('Update Estimate Status'))
      );
  }

  updateUserMessagesToUserCompanyFlag(token, noticeInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/user_notice/update_to_company_user_read_status`,
        noticeInfo,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<any>('Update Estimate Status'))
      );
  }

  updateEstimateFromUserReadStatus(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/estimate/update_from_user_read_status`,
        estimateInfo,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<any>('Update Estimate Status'))
      );
  }

  updateEstimateFromUserFlag(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/estimate/update_from_user_read_flag`,
        estimateInfo,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<any>('Update Estimate Flag'))
      );
  }

  updateEstimateToUserFlag(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/estimate/update_to_user_read_flag`,
        estimateInfo,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<any>('Update Estimate Flag'))
      );
  }

  updateEstimateBiko2(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/estimate/update_biko2_status`,
        estimateInfo,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<any>('Update Estimate Value'))
      );
  }

  updateEstimateValue(token, estimateInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post(
        `${this.AUTH_SERVER}/estimate/update_value`,
        estimateInfo,
        this.httpOptions
      )
      .pipe(
        tap((_) => console.log(`Estimate updated`)),
        catchError(this.handleError<any>('Update Estimate Value'))
      );
  }

  getClientsList(token, user): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/member/get_user_list`,
        user,
        this.httpOptions
      )
      .pipe(
        tap((clients) => console.log('Clients fetched!')),
        catchError(this.handleError<any>('Client error', []))
      );
  }

  getClient(token, target_user_id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/member/get_user`,
        target_user_id,
        this.httpOptions
      )
      .pipe(
        tap((clients) => console.log('target_user fetched!')),
        catchError(this.handleError<any>('Client error', []))
      );
  }

  updateClientComment(token, userInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(
        `${this.AUTH_SERVER}/member/update_comment`,
        userInfo,
        this.httpOptions
      )
      .pipe(
        tap((clients) => console.log('target_user fetched!')),
        catchError(this.handleError<any>('Client error', []))
      );
  }

  getKbn(token) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post<any>(
      `${this.AUTH_SERVER}/common/get_kbn`,
      this.httpOptions
    );
  }

  getKbnOLD(token, id): Observable<Kbn[]> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<Kbn[]>(
        `${this.AUTH_SERVER}/common/get_kbn`,
        { kbn_id: id },
        this.httpOptions
      )
      .pipe(
        tap((kbn) => console.log('kbn fetched!')),
        catchError(this.handleError<Kbn[]>('Get Groups', []))
      );
  }

  get_company_type(): Observable<any> {
    return this.httpClient.get(`${this.AUTH_SERVER}/static/get_company_type`);
  }

  getCategoryTypes(): Observable<any> {
    return this.httpClient.get(`${this.AUTH_SERVER}/static/get_category_types`);
  }

  getTerms(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get(`${this.AUTH_SERVER}/static/get_term`);
  }

  getPrivacy(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get(`${this.AUTH_SERVER}/static/get_privacy`);
  }

  getLicense(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get(`${this.AUTH_SERVER}/static/get_lisence`);
  }

  getLicenseLocal(): Observable<any> {
    return this.httpClient.get('assets/data/3rdpartylicenses.txt', {
      responseType: 'text'
    });
  }

  getFAQ(token): Observable<Question[]> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .get<QuestionListResponse>(`${this.AUTH_SERVER}/static/get_question`)
      .pipe(map(({ result: { questions } }) => questions));
  }

  getSystemMessages(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get<any>(
      `${this.AUTH_SERVER}/admin_notice/get_notice`,
      this.httpOptions
    );
  }

  getText(token) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get<{
      status: Record<string, any>;
      result: Record<string, string>;
    }>(`${this.AUTH_SERVER}/static/get_text`, this.httpOptions);
  }

  getPaymentGateway(token) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get<{
      status: Record<string, any>;
      result: Record<string, string>;
    }>(`${this.AUTH_SERVER}/static/get_payment_gateway`, this.httpOptions);
  }

  getCompanyTypes() {
    return this.httpClient.get<{
      status: Record<string, any>;
      result: Record<string, string>;
    }>(`${this.AUTH_SERVER}/static/company_types`);
  }

  getUser(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get(`${this.AUTH_SERVER}/management/get_userInfo`);
  }

  getRankInfo(token) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<any>(`${this.AUTH_SERVER}/rank/get_rank_info`, {}, this.httpOptions)
      .pipe(
        tap((Groups) => console.log('Ranks fetched!')),
        catchError(this.handleError<any>('Get Groups', []))
      );
  }

  saveRank(token, rank_id, clientList, noClientList): Observable<any> {
    const param = {
      rank_id: rank_id,
      target_user: clientList,
      no_target_user: noClientList
    };

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient
      .post<Group[]>(
        `${this.AUTH_SERVER}/rank/add_rank_user`,
        param,
        this.httpOptions
      )
      .pipe(
        tap((Groups) => console.log('add to rank!')),
        catchError(this.handleError<Group[]>('Group detail error', []))
      );
  }

  getMyCompanyOLD(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.get(`${this.AUTH_SERVER}/company/get_companyInfo`);
  }

  getMyCompany(token, company_id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/company/get_company`,
      { company_id: company_id },
      this.httpOptions
    );
  }

  getCompanyBases(token, base_info): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/company/get_company_base`,
      base_info,
      this.httpOptions
    );
  }

  getCompanyUsers(token, company_id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/company/get_company_user`,
      { company_id: company_id },
      this.httpOptions
    );
  }

  getCompanyOpenHours(token, base_info): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/company/get_openin_hours`,
      base_info,
      this.httpOptions
    );
  }

  updateMyCompany(token, companyInfo, updateCompany): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    if (updateCompany === true) {
      return this.httpClient.post(
        `${this.AUTH_SERVER}/company/update_company`,
        companyInfo,
        this.httpOptions
      );
    } else {
      return this.httpClient.post(
        `${this.AUTH_SERVER}/company/store`,
        companyInfo,
        this.httpOptions
      );
    }
  }

  updateMyBaseCompany(token, baseInfo, updateBase): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    if (updateBase === true) {
      return this.httpClient.post(
        `${this.AUTH_SERVER}/company/update_company_base`,
        baseInfo,
        this.httpOptions
      );
    } else {
      return this.httpClient.post(
        `${this.AUTH_SERVER}/company/add_company_base`,
        baseInfo,
        this.httpOptions
      );
    }
  }

  deleteMyBaseCompany(token, baseInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/company/delete_company_base`,
      baseInfo,
      this.httpOptions
    );
  }

  addPlanToCompanyUser(token, planInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/join_plan/change_join_plan`,
      planInfo,
      this.httpOptions
    );
  }

  addCompanyUsers(token, companyManagementInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/company/add_company_users`,
      companyManagementInfo,
      this.httpOptions
    );
  }

  updateCompanyUsers(token, companyManagementInfo): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/company/update_company_user`,
      companyManagementInfo,
      this.httpOptions
    );
  }

  getOtherCompany(token, companyId): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.httpClient.post(
      `${this.AUTH_SERVER}/company/get_other_companyInfo`,
      companyId,
      this.httpOptions
    );
  }

  getMarketList(token): Observable<Market[]> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    const res = this.httpClient.get<MarketListResponse>(
      `${this.AUTH_SERVER}/market`,
      this.httpOptions
    );
    res.forEach(() => console.log('Market list fetched'));
    return res.pipe(map(({ result: { markets } }) => markets));
  }

  getMarket(
    id: number,
    token,
    { year, month }: { year?: number; month?: number } = {}
  ): Observable<Market[]> {
    let params;
    let options = { Headers, params };

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    params = {} as Record<string, string>;
    if (year && month) {
      params.date = `${year}-${month.toString().padStart(2, '0')}`;
    }
    options.params = params;
    const res = this.httpClient.get<MarketListResponse>(
      `${this.AUTH_SERVER}/market/${id}`,
      options
    );
    res.forEach(() =>
      console.log(`Market for material ${id} fetched.`, params)
    );
    return res.pipe(map(({ result: { markets } }) => markets));
  }

  verifyUser(code) {
    return this.httpClient
      .post<any>(`${this.AUTH_SERVER}/auth/verify_user`, code)
      .pipe(catchError(this.handleError<any>('User not verified')));
  }

  //Versionの番号を送ってストアへ行くか確認
  checkVersion(versionCode) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient
      .post<any>(`${this.AUTH_SERVER}/auth/version_check`, versionCode)
      .pipe(catchError(this.handleError<any>('version is different')));
  }

  getAddressInformation(zipcode) {
    let zipHttpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        dataType: 'jsonp',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/jsonp'
      })
    };
    let zipHttpOptions = {
      headers: new HttpHeaders({
        'x-rapidapi-key': '6a8132c070mshf0d982ab8daf9a4p1745cejsnd73f804050cb',
        'x-rapidapi-host': 'postcodejp-api.p.rapidapi.com'
      })
    };
    return this.httpClient
      .get<any>(
        `https://postcodejp-api.p.rapidapi.com/postcodes?postcode=${zipcode}`,
        zipHttpOptions
      )
      .pipe(catchError(this.handleError<any>('Zip Code error')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      if (error.status === 503) {
        this.authService.maintenanceLogout('maintenance');
        this.loadingService.showToast(
          '大変申し訳ありませんがメンテナンス中です。見積りを作成してる場合は終わってから作り続けてください。'
        );
        this.router.navigateByUrl('/maintenance');
      }

      if (error.status === 401) {
        this.authService.logout(null, 'login');
        this.loadingService.showToast(
          'エラーが起こりました。再度ログインしてください。'
        );
      }
      if (error.status === 418) {
        this.authService.logout(null, 'login');
        this.loadingService.showToast('エラーが起こりました。');
      }
      if (error.status === 429) {
        this.authService.logout(null, 'login');
        this.loadingService.showToast(
          'リクエストは多過ぎたため、数秒待ってからまた試してください。'
        );
      }
      return of(result as T);
    };
  }
}
